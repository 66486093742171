import { SAVE_MULTITUDES, SAVE_CURRENT_MULTITUDE } from '../../../constants/ActionTypes'

const initialState = {
  multitudes: [],
  currentMultitude: {},
  pagination: {
    total: 0
  },
}

const multitudeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_MULTITUDES:
      return {
        ...state,
        multitudes: action.data,
        pagination: action.meta
      }
    case SAVE_CURRENT_MULTITUDE:
      return {
        ...state,
        currentMultitude: { ...state.currentMultitude, ...action.data },
      };
    default:
      return state
  }
}

export default multitudeReducer
