import { FETCH_ROLES } from '../../../constants/ActionTypes'

const initialState = {
  roles: []
}
const roleReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ROLES:
      return {
        ...state,
        roles: [...action.data]
      }
    default:
      return state
  }
}
export default roleReducer
