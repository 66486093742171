export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const FETCH_ROLES = 'FETCH_ROLES'
export const FETCH_PERMISSIONS = 'FETCH_PERMISSIONS'
export const FETCH_USERS = 'FETCH_USERS'
export const CREATE_USER = 'CREATE_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const DELETE_USER = 'DELETE_USER'
export const UPDATE_INSURANCE_POLICY = 'UPDATE_INSURANCE_POLICY'
export const FETCH_INSURANCE_POLICY = 'FETCH_INSURANCE_POLICY'
export const FETCH_PARENT_INTEREST_POLICY = 'FETCH_PARENT_INTEREST_POLICY'
export const UPDATE_PARENT_INTEREST_POLICY = 'UPDATE_PARENT_INTEREST_POLICY'
export const UPDATE_ANGEL_SERVICE_POLICY = 'UPDATE_ANGEL_SERVICE_POLICY'
export const FETCH_ANGEL_SERVICE_POLICY = 'FETCH_ANGEL_SERVICE_POLICY'
export const FETCH_SCHOOLS = 'FETCH_SCHOOLS'
export const SAVE_SCHOOLS = 'SAVE_SCHOOLS'
export const FETCH_SCHOOL_USERS = 'FETCH_SCHOOL_USERS'
export const SAVE_SCHOOL_USERS = 'SAVE_SCHOOL_USERS'
export const CREATE_SCHOOL_USERS = 'CREATE_SCHOOL_USERS'
export const TOGGLE_MODAL_CREATE_USER = 'TOGGLE_MODAL_CREATE_USER'
export const RESETTING_PASSWORD = 'RESETTING_PASSWORD'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const CHANGING_PASSWORD = 'CHANGING_PASSWORD'
export const RESET_CHANGE_PASSWORD_RESULTS = 'RESET_CHANGE_PASSWORD_RESULTS'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'

// requests
export * from './request/ActionTypes'
export const FETCH_SETTINGS = 'FETCH_SETTINGS'
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS'

// Notes
export const FETCH_NOTES = 'FETCH_NOTES'
export const CREATE_NOTE = 'CREATE_NOTE'
export const ADDING_NOTE = 'ADDING_NOTE'
export const RESET_NOTE_STATUS = 'RESET_NOTE_STATUS'

// Parents
export * from './parent/ActionTypes'

// Courses
export * from './course/ActionTypes'

// Coupon
export * from './coupon/ActionTypes'

// Angels
export * from './angel/ActionTypes'

// Multitudes
export * from './multitude/ActionTypes'

// Advertise
export * from './advertise/ActionTypes'

// Capital
export * from './capital/ActionTypes'

// Ocb transaction
export * from './ocb-transaction/ActionTypes'

// 9pay transaction
export * from './ninepay-transaction/ActionTypes'
