// ** Redux, Thunk & Root Reducer Imports
import thunk from 'redux-thunk'
import createDebounce from 'redux-debounced'
import rootReducer from '../reducers/rootReducer'
import { createStore, applyMiddleware, compose } from 'redux'

// ** init middleware
const middlewares = [thunk, createDebounce()]
import mixpanel from 'mixpanel-browser'
import MixpanelMiddleware from 'redux-mixpanel-middleware'
if (process.env.REACT_APP_IS_MIX_PANEL_ENABLED == 'true') {
    console.log('Mixpanel enabled...');
    mixpanel.init(process.env.REACT_APP_MIX_PANEL_KEY || '93034ee200b6e22af9f55ca7e65f4d6f', { debug: process.env.REACT_APP_IS_MIX_PANEL_DEBUG_ENABLED == 'true' || false });
    const mixpanelMiddleware = new MixpanelMiddleware(mixpanel)
    middlewares.push(mixpanelMiddleware);
}


// ** Dev Tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// ** Create store
const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(...middlewares)))

export { store }
