export const FETCH_GET_NINEPAY_BALANCE = 'FETCH_GET_NINEPAY_BALANCE'
export const LOADING_GET_NINEPAY_BALANCE = 'LOADING_NINEPAY_GET_BALANCE';

export const FETCH_NINEPAY_BANK_LIST = 'FETCH_NINEPAY_BANK_LIST';
export const LOADING_GET_NINEPAY_BANK_LIST = 'LOADING_GET_NINEPAY_BANK_LIST';

export const FETCH_LIST_NINEPAY_TRANSFER_TRANSACTIONS = 'FETCH_LIST_NINEPAY_TRANSFER_TRANSACTIONS'
export const LOADING_LIST_NINEPAY_TRANSFER_TRANSACTIONS = 'LOADING_LIST_NINEPAY_TRANSFER_TRANSACTIONS'
export const SELECT_NINEPAY_TRANSFER_TRANSACTION = 'SELECT_NINEPAY_TRANSFER_TRANSACTION'

export const FETCH_LIST_NINEPAY_TRANSFER_APPROVAL_TRANSACTIONS = 'FETCH_LIST_NINEPAY_TRANSFER_APPROVAL_TRANSACTIONS'
export const LOADING_LIST_NINEPAY_TRANSFER_APPROVAL_TRANSACTIONS = 'LOADING_LIST_NINEPAY_TRANSFER_APPROVAL_TRANSACTIONS'
export const SELECT_NINEPAY_TRANSFER_APPROVAL_TRANSACTION = 'SELECT_NINEPAY_TRANSFER_APPROVAL_TRANSACTION'

export const FETCH_LIST_NINEPAY_TOPUP_TRANSACTIONS = 'FETCH_LIST_NINEPAY_TOPUP_TRANSACTIONS'
export const LOADING_LIST_NINEPAY_TOPUP_TRANSACTIONS = 'LOADING_LIST_NINEPAY_TOPUP_TRANSACTIONS'
export const SELECT_NINEPAY_TOPUP_TRANSACTION = 'SELECT_NINEPAY_TOPUP_TRANSACTION'

export const FETCH_LIST_NINEPAY_REPAYMENT_TRANSACTIONS = 'FETCH_LIST_NINEPAY_REPAYMENT_TRANSACTIONS'
export const LOADING_LIST_NINEPAY_REPAYMENT_TRANSACTIONS = 'LOADING_LIST_NINEPAY_REPAYMENT_TRANSACTIONS'
export const SELECT_NINEPAY_REPAYMENT_TRANSACTION = 'SELECT_NINEPAY_REPAYMENT_TRANSACTION'

export const FETCH_LIST_NINEPAY_ANGEL_VIRTUAL_ACCOUNTS = 'FETCH_LIST_NINEPAY_ANGEL_VIRTUAL_ACCOUNTS'
export const LOADING_LIST_NINEPAY_ANGEL_VIRTUAL_ACCOUNTS = 'LOADING_LIST_NINEPAY_ANGEL_VIRTUAL_ACCOUNTS'

export const FETCH_LIST_NINEPAY_REQUEST_VIRTUAL_ACCOUNTS = 'FETCH_LIST_NINEPAY_REQUEST_VIRTUAL_ACCOUNTS'
export const LOADING_LIST_NINEPAY_REQUEST_VIRTUAL_ACCOUNTS = 'LOADING_LIST_NINEPAY_REQUEST_VIRTUAL_ACCOUNTS'