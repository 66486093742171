import { 
  FETCH_LIST_OCB_TRANSACTIONS,
  LOADING_LIST_OCB_TRANSACTIONS,
  SELECT_OCB_TRANSACTION,
  FETCH_LIST_OCB_REPAYMENT_TRANSACTIONS,
  LOADING_LIST_OCB_REPAYMENT_TRANSACTIONS,
  SELECT_OCB_REPAYMENT_TRANSACTION
} from '../../../constants/ActionTypes'

const initialState = {
  transactions: [],
  pagination: {
    total: 0
  },
  loadingList: false
}
const ocbTransactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LIST_OCB_TRANSACTIONS:
    case FETCH_LIST_OCB_REPAYMENT_TRANSACTIONS:
      return {
        ...state,
        transactions: action.data,
        transaction: null,
        pagination: action.meta,
        loadingList: false
      }
    case LOADING_LIST_OCB_TRANSACTIONS:
    case LOADING_LIST_OCB_REPAYMENT_TRANSACTIONS:
      return {
        ...state,
        loadingList: action.data === undefined ? true : action.data
      }
    case SELECT_OCB_TRANSACTION:
    case SELECT_OCB_REPAYMENT_TRANSACTION:
      return {
        ...state,
        transaction: action.payload
      }

    default:
      return state
  }
}
export default ocbTransactionReducer;