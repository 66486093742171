// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import systemRoles from './role'
import systemPermissions from './permission'
import user from './user'
import request from './request'
import settings from './settings'
import school from './school'
import course from './course'
import note from './note'
import parents from './parents'
import angel from './angel'
import advertise from './advertise'
import coupon from './coupon'
import ocbTransaction from './ocb-transaction'
import ninepayTransaction from './ninepay-transaction'
import mailingLists from './mailing-list'
import transaction from './transaction'
import multitude from './multitude'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  systemRoles,
  systemPermissions,
  user,
  request,
  settings,
  school,
  course,
  parents,
  note,
  coupon,
  angel,
  advertise,
  multitude,
  ocbTransaction,
  ninepayTransaction,
  mailingLists,
  transaction
})

export default rootReducer
