import {
  FETCH_LIST_ANGELS,
  FETCH_LIST_MULTITUDE_ANGELS,
  LOADING_LIST_ANGELS,
  GET_ANGEL_DETAIL,
  LOADING_ANGEL_DETAIL,
  CLEAR_ANGEL_DETAIL,
  FETCH_ANGEL_TRANSACTIONS,
  LOADING_LIST_MULTITUDE_ANGELS,
  FETCH_ANGELS_OUT_OF_MULTITUDE,
  LOADING_ANGELS_OUT_OF_MULTITUDE,
} from "../../../constants/ActionTypes";

const initialState = {
  angels: [],
  multitudeAngels: [],
  angelsOutOfMultitude: [],
  pagination: {
    total: 0,
  },
  multitudeAngelPagination: {
    total: 0,
  },
  angelsOutOfMultitudePagination: {
    total: 0,
  },
  loadingList: false,
  loadingMultitudeList: false,
  loadingAngelsOutOfMultitude: false,
};
const angelReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LIST_ANGELS:
      return {
        ...state,
        angels: action.data,
        pagination: action.meta,
        loadingList: false,
      };
    case FETCH_LIST_MULTITUDE_ANGELS:
      return {
        ...state,
        multitudeAngels: action.data,
        multitudeAngelPagination: action.meta,
        loadingMultitudeList: false,
      };
    case FETCH_ANGELS_OUT_OF_MULTITUDE:
      return {
        ...state,
        angelsOutOfMultitude: action.data,
        angelsOutOfMultitudePagination: action.meta,
        loadingAngelsOutOfMultitude: false,
      };
    case GET_ANGEL_DETAIL:
      const angel = action.data;
      if (angel?.angelEKYC?.identityCardFront) {
        angel.angelEKYC.identityCardFront = [
          action.data.angelEKYC.identityCardFront,
        ];
      }
      if (angel?.angelEKYC?.identityCardBack) {
        angel.angelEKYC.identityCardBack = [
          action.data.angelEKYC.identityCardBack,
        ];
      }
      if (angel?.angelEKYC?.otherPapers) {
        angel.angelEKYC.otherPapers = [action.data.angelEKYC.otherPapers];
      }
      return {
        ...state,
        angelDetail: angel,
        loadingDetail: false,
      };
    case LOADING_LIST_ANGELS:
      return {
        ...state,
        loadingList: action.data === undefined ? true : action.data,
      };
    case LOADING_LIST_MULTITUDE_ANGELS:
      return {
        ...state,
        loadingMultitudeList: action.data === undefined ? true : action.data,
      };
    case LOADING_ANGELS_OUT_OF_MULTITUDE:
      return {
        ...state,
        loadingAngelsOutOfMultitude: action.data === undefined ? true : action.data,
      };
    case LOADING_ANGEL_DETAIL:
      return {
        ...state,
        loadingDetail: action.data === undefined ? true : action.data,
      };
    case FETCH_ANGEL_TRANSACTIONS:
      return {
        ...state,
        transactions: action?.data,
        transactionsMeta: action?.meta,
        loadingDetail: false,
      };

    case CLEAR_ANGEL_DETAIL:
      return {
        ...state,
        angelDetail: {},
      };
    default:
      return state;
  }
};
export default angelReducer;
