import { 
  FETCH_LIST_TRANSACTIONS,
  LOADING_LIST_TRANSACTIONS,
  SELECT_TRANSFER_TRANSACTION,
} from '../../../constants/ActionTypes'

const initialState = {
  transactions: [],
  pagination: {
    total: 0
  },
  transfer: {},
  loadingList: false
}
const transactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LIST_TRANSACTIONS:
      return {
        ...state,
        transactions: action.data,
        transaction: null,
        pagination: action.meta,
        loadingList: false
      }
    case LOADING_LIST_TRANSACTIONS:
      return {
        ...state,
        loadingList: action.data === undefined ? true : action.data
      }
    case SELECT_TRANSFER_TRANSACTION:
      return {
        ...state,
        transfer: action.payload
      }
    

    default:
      return state
  }
}
export default transactionReducer;