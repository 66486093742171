import { 
  FETCH_GET_NINEPAY_BALANCE,
  LOADING_GET_NINEPAY_BALANCE,
  FETCH_NINEPAY_BANK_LIST,
  LOADING_GET_NINEPAY_BANK_LIST,
  FETCH_LIST_NINEPAY_TOPUP_TRANSACTIONS,
  LOADING_LIST_NINEPAY_TOPUP_TRANSACTIONS,
  SELECT_NINEPAY_TOPUP_TRANSACTION,
  FETCH_LIST_NINEPAY_REPAYMENT_TRANSACTIONS,
  LOADING_LIST_NINEPAY_REPAYMENT_TRANSACTIONS,
  SELECT_NINEPAY_REPAYMENT_TRANSACTION,
  FETCH_LIST_NINEPAY_TRANSFER_TRANSACTIONS,
  LOADING_LIST_NINEPAY_TRANSFER_TRANSACTIONS,
  FETCH_LIST_NINEPAY_TRANSFER_APPROVAL_TRANSACTIONS,
  LOADING_LIST_NINEPAY_TRANSFER_APPROVAL_TRANSACTIONS,
  SELECT_NINEPAY_TRANSFER_TRANSACTION,
  FETCH_LIST_NINEPAY_ANGEL_VIRTUAL_ACCOUNTS,
  LOADING_LIST_NINEPAY_ANGEL_VIRTUAL_ACCOUNTS,
  FETCH_LIST_NINEPAY_REQUEST_VIRTUAL_ACCOUNTS,
  LOADING_LIST_NINEPAY_REQUEST_VIRTUAL_ACCOUNTS,
} from '../../../constants/ActionTypes'

const initialState = {
  balance: {},
  banks: [],
  transactions: [],
  transaction: {},
  pagination: {
    total: 0
  },
  virtualAccounts: [],
  virtualAccountsPagination: {
    total: 0
  },
  loadingList: false
}
const ninepayTransactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GET_NINEPAY_BALANCE:
    case LOADING_GET_NINEPAY_BALANCE:
      return {
        ...state,
        balance: action.data,
      }

    case FETCH_NINEPAY_BANK_LIST:
    case LOADING_GET_NINEPAY_BANK_LIST:
      return {
        ...state,
        banks: action.data,
      }

    case FETCH_LIST_NINEPAY_TRANSFER_TRANSACTIONS:
    case FETCH_LIST_NINEPAY_TRANSFER_APPROVAL_TRANSACTIONS:
    case FETCH_LIST_NINEPAY_TOPUP_TRANSACTIONS:
    case FETCH_LIST_NINEPAY_REPAYMENT_TRANSACTIONS:
      return {
        ...state,
        transactions: action.data,
        transaction: null,
        pagination: action.meta,
        loadingList: false
      }

    case FETCH_LIST_NINEPAY_ANGEL_VIRTUAL_ACCOUNTS:
    case FETCH_LIST_NINEPAY_REQUEST_VIRTUAL_ACCOUNTS:
      return {
        ...state,
        virtualAccounts: action.data,
        virtualAccountsPagination: action.meta,
        loadingList: false
      }

    case LOADING_LIST_NINEPAY_TRANSFER_TRANSACTIONS:
    case LOADING_LIST_NINEPAY_TRANSFER_APPROVAL_TRANSACTIONS:
    case LOADING_LIST_NINEPAY_TOPUP_TRANSACTIONS:
    case LOADING_LIST_NINEPAY_REPAYMENT_TRANSACTIONS:
    case LOADING_LIST_NINEPAY_ANGEL_VIRTUAL_ACCOUNTS:
    case LOADING_LIST_NINEPAY_REQUEST_VIRTUAL_ACCOUNTS:
      return {
        ...state,
        loadingList: action.data === undefined ? true : action.data
      }

    case SELECT_NINEPAY_TRANSFER_TRANSACTION:
    case SELECT_NINEPAY_TOPUP_TRANSACTION:
    case SELECT_NINEPAY_REPAYMENT_TRANSACTION:
      return {
        ...state,
        transaction: action.payload
      }

    default:
      return state
  }
}
export default ninepayTransactionReducer;