import { capitalizeFirstLetter } from '../../utility/Utils'

export const REQUEST_STATUS = {
  NEW: 0,
  VERIFYING: 1,
  APPRAISING: 2,
  ACCEPTED: 3,
  FULFILLED: 4,
  DISBURSED: 5,
  COLLECTING: 6,
  COMPLETED: 7,
  OVERDUE: 8,
  CANCELED: 9,
  REJECTED: 10,
  FULFILL_PENDING: 11,
  DEFAULT: 12
}

export const SCHOOL_REQUEST_STATUS = {
  VERIFYING: 0,
  ACCEPTED: 1,
  DENY: 2
}

export const REPAYMENT_STATUS = {
  WAITING: 0,
  PROCESSING: 1,
  COMPLETED: 2
}

export const REPAYMENT_PERIOD = {
  MONTHLY: 0,
  SEMESTERLY: 1
}

export const REPAYMENT_PERIOD_NAME = {
  [REPAYMENT_PERIOD.MONTHLY]: 'Hàng tháng',
  [REPAYMENT_PERIOD.SEMESTERLY]: 'Cuối kỳ'
}

export const REPAYMENT_STATUS_NAME = Object.keys(REPAYMENT_STATUS).reduce((result, key) => ({
  ...result,
  [REPAYMENT_STATUS[key]]: capitalizeFirstLetter(key)
}), {})

export const REQUEST_STATUS_NAME = Object.keys(REQUEST_STATUS).reduce((result, key) => ({
  ...result,
  [REQUEST_STATUS[key]]: capitalizeFirstLetter(key)
}), {})

export const REQUEST_VERIFYING_PRIORITIZE = {
  LOW: [0, 10],
  MEDIUM: [10, 15],
  HIGH: [15]
}

export const REQUEST_APPRAISING_PRIORITIZE = {
  LOW: [0, 2],
  MEDIUM: [2, 4],
  HIGH: [4]
}

export const REQUEST_ACCEPTED_PRIORITIZE = {
  LOW: [0, 12],
  MEDIUM: [12, 24],
  HIGH: [24]
}

export const STAKEHOLDER_TYPE = {
  ADMIN: 'ADMIN',
  SCHOOL: 'SCHOOL',
  SYSTEM: 'SYSTEM'
}

export const SETTING_TYPES = {
  INSURANCE: 'INSURANCEPOLICY',
  PARENT: 'PARENT',
  LENDING: 'LENDINGPRODUCTPOLICY',
  PARENTINTERESTPOLICY: 'PARENTINTERESTPOLICY',
  ANGEL: 'ANGELSERVICEPOLICY'
}

export const REPAYMENT_MILESTONE_TYPE  = {
  REPAYMENT: 0,
  UPFRONT: 1,
  BORROWER_FEE: 10,
  BORROWER_INTEREST: 15,
  SCHOOL_FEE: 30
}