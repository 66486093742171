import { FETCH_LIST_COUPONS, LOADING_LIST_COUPONS, GET_COUPON_DETAIL, LOADING_COUPON_DETAIL, CLEAR_COUPON_DETAIL } from '../../../constants/ActionTypes'

const initialState = {
  coupons: [],
  pagination: {
    total: 0
  },
  loadingList: false
}
const couponReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LIST_COUPONS:
      return {
        ...state,
        coupons: action.data,
        pagination: action.meta,
        loadingList: false
      }
    case GET_COUPON_DETAIL:
      return {
        ...state,
        couponDetail: action.data,
        loadingDetail: false
      }
    case LOADING_LIST_COUPONS:
      return {
        ...state,
        loadingList: action.data === undefined ? true : action.data
      }
    case LOADING_COUPON_DETAIL:
      return {
        ...state,
        loadingDetail: action.data === undefined ? true : action.data
      }
  
    case CLEAR_COUPON_DETAIL:
      return {
        ...state,
        couponDetail: {}
      }
    default:
      return state
  }
}
export default couponReducer
