
const initialState = {
  lists: [],
  templates: [],
  loadingLists: false,
}
const mailingListReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCHED_MAILING_LISTS':
      return {
        ...state,
        lists: action.data?.lists || [],
        templates: action.data?.templates || [],
        loadingLists: false
      }


    case 'CLEAR_MAILING_LISTS':
      return {
        ...state,
        lists: []
      }

    case 'LOADING_MAILING_LISTS':
      return {
        ...state,
        loadingLists: action.data === undefined ? true : action.data
      }

    default:
      return state
  }
}
export default mailingListReducer
