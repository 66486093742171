import { CREATE_USER, FETCH_USERS } from '../../../constants/ActionTypes'

const initialState = {
  users: [],
  success: 0,
  error: null
}
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_USER:
      return {
        ...state,
        success: (state.success + 1)
      }
    case FETCH_USERS:
      return {
        ...state,
        users: action.data
      }

    default:
      return state
  }
}
export default userReducer
