import { find } from 'lodash'
import { FETCH_SETTINGS, UPDATE_SETTINGS } from '../../../constants/ActionTypes'
import { SETTING_TYPES } from '../../../constants/request/Types'

const transformPolicyData = (data) => {
  const state = data.reduce((acc, setting) => {
    const { settingType } = setting
    return { ...acc, [settingType.toUpperCase()]: { ...(acc[settingType] || []), ...setting } }
  }, {})

  Object.keys(state).map(type => {
    const data = state[type]

    switch (data.settingType.toUpperCase()) {
      case SETTING_TYPES.ANGEL:
        state[type]['data'] = data.data
        break
      default:
        if (data?.data[0]?.type && data?.data[0]?.type === 'collapsible') {
          const tmpData = []
          data.data.map(datum => {
            const settings = datum.data.reduce((acc, setting) => {
              const { group } = setting
              return { ...acc, [group]: [...(acc[group] || []), setting] }
            }, {})
            tmpData.push(
              {
                ...data,
                key: datum.key,
                name: datum.name,
                enabled: datum.enabled,
                type: datum.type,
                data: settings
              })
            return datum
          })
          state[type]['data'] = tmpData
        } else if (data?.data[0]?.group) {
          state[type]['data'] = data.data.reduce((acc, setting) => {
            const { group } = setting
            return { ...acc, [group]: [...(acc[group] || []), setting] }
          }, {})
        }
        break
    }
  })

  return state
}

const extractLendingProductCodes = (data) => {
  const state = data.reduce((acc, setting) => {
    const { settingType } = setting
    return { ...acc, [settingType]: { ...(acc[settingType] || []), ...setting } }
  }, {})

  return (state.lendingProductPolicy?.data || []).map(lendingProduct => {
    const foundProductCode = find(lendingProduct.data, setting => setting.key === 'product_code')
    return {
      key: lendingProduct.key,
      name: lendingProduct.name,
      productCode: (foundProductCode && foundProductCode.value) || ''
    }
  })
}

const transformData = (data) => {
  const state = data.reduce((acc, setting) => {
    const { settingType } = setting
    return { ...acc, [settingType]: { ...(acc[settingType] || []), ...setting } }
  }, {})

  Object.keys(state).map(type => {
    const data = state[type]
    if (data.data[0].group) {
      state[type] = data.data
    }
  })

  return state
}

const initialState = {
  settings: {},
  rawSettings: {},
  success: 0,
  error: null
}

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SETTINGS:
      return {
        ...state,
        success: (state.success + 1)
      }
    case FETCH_SETTINGS:
      return {
        ...state,
        settings: transformPolicyData(action.data),
        lendingProductCodes: extractLendingProductCodes(action.data),
        rawSettings: action.data
      }

    default:
      return state
  }
}
export default settingsReducer
