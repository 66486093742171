export const SAVE_CURRENT_PARENTS = "SAVE_CURRENT_PARENTS";
export const FETCH_PARENT_CIC = "FETCH_PARENT_CIC";
export const FETCH_PARENT_OCR="FETCH_PARENT_OCR";
export const FETCH_PARENT_BACKGROUND = "FETCH_PARENT_BACKGROUND";
export const LOADING_CIC = "LOADING_CIC";
export const LOADING_OCR = "LOADING_OCR";
export const LOADING_BACKGROUND = "LOADING_BACKGROUND";
export const SAVE_PARENTS = 'SAVE_PARENTS';
export const LOADING_LIST_PARENTS = 'LOADING_LIST_PARENTS';
export const FETCH_REFERENCE_BACKGROUND = "FETCH_REFERENCE_BACKGROUND";
export const LOADING_REFERENCE_BACKGROUND = "LOADING_REFERENCE_BACKGROUND";
