import moment from 'moment'
import { CLEAR_REQUEST_DETAIL, FETCH_LIST_REQUESTS, GET_OVERVIEW_REQUESTS, GET_PREVIEW_LIST_INVESTMENT, GET_REQUEST_DETAIL, LOADING_LIST_REQUESTS, LOADING_PREVIEW_LIST_INVESTMENT, LOADING_REQUEST_DETAIL } from '../../../constants/ActionTypes'

const initialState = {
  requests: [],
  overview: {},
  requestDetail: {},
  pagination: {
    total: 0
  },
  loadingDetail: false,
  loadingList: false,
  loadingGetPreviewListInvestment: false,
  previewListInvestment: {}
}
const requestReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LIST_REQUESTS:
      return {
        ...state,
        requests: action.data,
        pagination: action.meta,
        loadingList: false
      }
    case LOADING_LIST_REQUESTS:
      return {
        ...state,
        loadingList: action.data === undefined ? true : action.data
      }

    case GET_OVERVIEW_REQUESTS:
      return {
        ...state,
        overview: action.data
      }

    case CLEAR_REQUEST_DETAIL:
      return {
        ...state,
        requestDetail: {}
      }

    case GET_REQUEST_DETAIL:
      const request = action.data
      if (request?.repaymentMilestones && Array.isArray(request.repaymentMilestones) && request.repaymentMilestones.length > 0) {
        const totalMilestone = request.repaymentMilestones.filter(milestone => milestone.type === 0).length
        request.repaymentMilestones = request.repaymentMilestones
          .map((milestones) => {
            milestones.turn = `${milestones.turn}/${totalMilestone}`
            // milestone upfront
            if (milestones.type === 1) {
              milestones.preValue = (request?.fees?.parentsInterestAmountBase || 0) + (request?.fees?.parentsPlatformFeeAmountBase || 0)
            }
            return milestones
          })
      }
      if (request?.reviewRepaymentMilestones && Array.isArray(request.reviewRepaymentMilestones) && request.reviewRepaymentMilestones.length > 0) {
        const totalMilestone = request.reviewRepaymentMilestones.filter(milestone => milestone.type === 0).length
        request.reviewRepaymentMilestones = request.reviewRepaymentMilestones
          .sort((a, b) => new Date(a.turn) - new Date(b.turn))
          .map((milestones, index, arr) => {
            milestones.turn = `${milestones.turn}/${totalMilestone}`
            return milestones
          })
      }
      if (request?.transactions && Array.isArray(request.transactions) && request.transactions.length > 0) {
        request.transactions = request.transactions
          .filter(transaction => transaction)
          .map((transaction) => {
            transaction.percent = `${((transaction?.amount ?? 0) / (request?.value ?? 0) * 100).toFixed(2)}%`
            return transaction
          })
      }
      return {
        ...state,
        requestDetail: action.data,
        previewListInvestment: {},
        loadingDetail: false
      }

    case LOADING_REQUEST_DETAIL:
      return {
        ...state,
        loadingDetail: action.data === undefined ? true : action.data
      }

    case LOADING_PREVIEW_LIST_INVESTMENT:
      return {
        ...state,
        loadingGetPreviewListInvestment: action.data === undefined ? true : action.data
      }

    case GET_PREVIEW_LIST_INVESTMENT:
      return {
        ...state,
        previewListInvestment: action.data
      }

    default:
      return state
  }
}
export default requestReducer
