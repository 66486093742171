import { SAVE_ADVERTISES, SAVE_CURRENT_ADVERTISE } from '../../../constants/ActionTypes'

const initialState = {
  advertises: [],
  currentAdvertise: {},
  pagination: {
    total: 0
  },
}

const advertiseReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_ADVERTISES:
      return {
        ...state,
        advertises: action.data,
        pagination: action.meta
      }
    case SAVE_CURRENT_ADVERTISE:
      return {
        ...state,
        currentAdvertise: { ...state.currentAdvertise, ...action.data },
      };
    default:
      return state
  }
}

export default advertiseReducer
