// **  Initial State
import {
  CHANGE_PASSWORD,
  CHANGING_PASSWORD,
  LOGIN,
  LOGOUT,
  RESET_CHANGE_PASSWORD_RESULTS
} from '../../../constants/ActionTypes'

const initialState = {
  userData: {}
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        userData: action.data,
        [action.config.storageTokenKeyName]: action[action.config.storageTokenKeyName],
        [action.config.storageRefreshTokenKeyName]: action[action.config.storageRefreshTokenKeyName]
      }
    case LOGOUT:
      const obj = {...action}
      delete obj.type
      return {...state, userData: {}, ...obj}
    case CHANGING_PASSWORD:
      return {
        ...state,
        changingPassword: action.data.loading
      }
    case RESET_CHANGE_PASSWORD_RESULTS:
      return {
        ...state,
        changePasswordResults: null
      }
    case CHANGE_PASSWORD:
      return {
        ...state,
        changingPassword: false,
        changePasswordResults: action.data
      }
    default:
      return state
  }
}

export default authReducer
