export const FETCH_LIST_ANGELS = 'FETCH_LIST_ANGELS'
export const LOADING_LIST_ANGELS = 'LOADING_LIST_ANGELS'
export const GET_ANGEL_DETAIL = 'GET_ANGEL_DETAIL'
export const FETCH_ANGEL_TRANSACTIONS = 'FETCH_ANGEL_TRANSACTIONS'
export const LOADING_ANGEL_DETAIL = 'LOADING_ANGEL_DETAIL'
export const CLEAR_ANGEL_DETAIL = 'CLEAR_ANGEL_DETAIL'
export const FETCH_LIST_MULTITUDE_ANGELS = 'FETCH_LIST_MULTITUDE_ANGELS'
export const LOADING_LIST_MULTITUDE_ANGELS = 'LOADING_LIST_MULTITUDE_ANGELS'
export const FETCH_ANGELS_OUT_OF_MULTITUDE = 'FETCH_ANGELS_OUT_OF_MULTITUDE'
export const LOADING_ANGELS_OUT_OF_MULTITUDE = 'LOADING_ANGELS_OUT_OF_MULTITUDE'
