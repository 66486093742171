import {
  SAVE_CURRENT_PARENTS,
  FETCH_PARENT_CIC,
  FETCH_PARENT_OCR,
  LOADING_CIC,
  LOADING_OCR,
  FETCH_PARENT_BACKGROUND,
  LOADING_BACKGROUND,
  SAVE_PARENTS,
  LOADING_LIST_PARENTS,
  FETCH_REFERENCE_BACKGROUND,
  LOADING_REFERENCE_BACKGROUND,
} from "../../../constants/ActionTypes";

export const STAKEHOLDER_TYPE = { PARENTS: 1, ANGEL: 2 };

const initialState = {
  parents: [],
  currentParent: {},
  currentParentCIC: {},
  currentParentOCR: {},
  currentParentBackgroundData: {},
  currentReferenceBackgroundData: {},
  loadingList: false,
  loadingCIC: false,
  loadingOCR: false,
  loadingBackgroundData: false,
  loadingReferenceBackgroundData: false,
  pagination: {
    total: 0,
  },
};

const parentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_PARENTS:
      return {
        ...state,
        parents: action.data,
        pagination: action.meta,
        loadingList: false,
      }

    case LOADING_LIST_PARENTS:
      return {
        ...state,
        loadingList: action.data === undefined ? true : action.data
      }

    case SAVE_CURRENT_PARENTS:
      return {
        ...state,
        currentParent: { ...state.currentParent, ...action.data },
      };

    case LOADING_CIC:
      return {
        ...state,
        loadingCIC: action.data === undefined ? true : action.data,
      };

    case FETCH_PARENT_CIC:
      return {
        ...state,
        currentParentCIC: action.data,
        loadingCIC: false,
      };

    //Huy Do
    //Parent Identified Card information OCR
    case FETCH_PARENT_OCR:
      return {
        ...state,
        currentParentOCR: action.data,
        loadingOCR: false,
      };
    case LOADING_OCR:
      return {
        ...state,
        loadingOCR: action.data === undefined ? true : action.data,
      };

    case LOADING_BACKGROUND:
      return {
        ...state,
        loadingBackgroundData: action.data === undefined ? true : action.data,
      };

    case FETCH_PARENT_BACKGROUND:
      return {
        ...state,
        currentParentBackgroundData: action.data,
        loadingBackgroundData: false,
      };

    case LOADING_REFERENCE_BACKGROUND:
      return {
        ...state,
        loadingReferenceBackgroundData: action.data === undefined ? true : action.data,
      };

    case FETCH_REFERENCE_BACKGROUND:
      return {
        ...state,
        currentReferenceBackgroundData: action.data,
        loadingReferenceBackgroundData: false,
      };

    default:
      return state;
  }
};

export default parentsReducer;
