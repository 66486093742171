import { ADDING_NOTE, CREATE_NOTE, FETCH_NOTES, RESET_NOTE_STATUS } from '../../../constants/ActionTypes'

const initialState = {
  notes: [],
  success: null,
  addingNote: false,
  error: null
}

const noteReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_NOTE_STATUS:
      return {
        ...state,
        success: null
      }
    case ADDING_NOTE:
      return {
        ...state,
        addingNote: action.data
      }
    case CREATE_NOTE:
      return {
        ...state,
        success: action.data.success,
        error: action.data?.error || null
      }
    case FETCH_NOTES:
      return {
        ...state,
        notes: action.data
      }

    default:
      return state
  }
}
export default noteReducer
