import { SAVE_COURSES, SAVE_CURRENT_COURSE } from '../../../constants/ActionTypes'

const initialState = {
  courses: [],
  currentCourse: {},
  pagination: {
    total: 0
  },
}

const courseReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_COURSES:
      return {
        ...state,
        courses: action.data,
        pagination: action.meta
      }
    case SAVE_CURRENT_COURSE:
      return {
        ...state,
        currentCourse: { ...state.currentCourse, ...action.data },
      };
    default:
      return state
  }
}

export default courseReducer
