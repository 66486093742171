import {
  RESET_PASSWORD,
  RESETTING_PASSWORD,
  SAVE_SCHOOL_USERS,
  SAVE_SCHOOLS,
  TOGGLE_MODAL_CREATE_USER
} from '../../../constants/ActionTypes'

const initialState = {
  schools: [],
  pagination: {
    total: 0
  },
  schoolUsers: [],
  isOpenModalCreateAccount: false,
  isResettingPassword: null,
  isResetPasswordResults: null
}

const schoolReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_SCHOOLS:
      return {
        ...state,
        schools: action.data,
        pagination: action.meta
      }
    case SAVE_SCHOOL_USERS:
      return {
        ...state,
        schoolUsers: action.payload.data
      }
    case TOGGLE_MODAL_CREATE_USER:
      return {
        ...state,
        isOpenModalCreateAccount: !state.isOpenModalCreateAccount
      }
    case RESETTING_PASSWORD:
      return {
        ...state,
        isResettingPassword: action.data
      }
    case RESET_PASSWORD:
      return {
        ...state,
        isResettingPassword: false,
        isResetPasswordResults: action.data
      }
    default:
      return state
  }
}

export default schoolReducer
